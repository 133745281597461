import React from "react"
import { Button } from "@chakra-ui/react"
import { useConfigContext } from "@app/providers/config"
import { useCheckoutContext } from "@app/providers/checkout"

type Props = {
  language: {
    checkout: string
    startShopping: string
  }
}

const Checkout: React.FC<Props> = ({ language }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { checkout, url, loading, gotoCheckout } = useCheckoutContext()

  const disabled = !checkout?.lineItems?.length
  const link = !disabled ? url : routes.HOMEPAGE

  return !disabled ? (
    <Button
      as="a"
      variant="solidSecondary"
      href={link}
      onClick={gotoCheckout}
      isLoading={loading}
      display="flex"
      textAlign="center"
      _hover={{ textDecoration: "none" }}
    >
      {language.checkout}
    </Button>
  ) : (
    <Button as="a" variant="solidSecondary" href={link} d="block" textAlign="center" _hover={{ textDecoration: "none" }}>
      {language.startShopping}
    </Button>
  )
}

export default React.memo(Checkout)
