import React from "react"
import { graphql, PageProps } from "gatsby"
import Page from "@app/components/Cart/Cart"

export type Props = PageProps<GatsbyTypes.PageCartQuery, GatsbyTypes.PageCartQueryVariables>

export const query = graphql`
  query PageCart {
    page: sanityPageCart {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 2 })
      additionalCheckoutButton
      additionalCartEmpty
      additionalStartShopping
      additionalSubtotal
      additionalDiscounts
      additionalRemove
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
