import React from "react"
import { Box, Container, Heading } from "@chakra-ui/react"

import Checkout from "@app/components/Checkout"
import { useCheckoutContext } from "@app/providers/checkout"
import PaymentTypes from "@app/components/PaymentTypes"
import { useContent } from "@app/hooks/useContent"
import CartSummary from "@app/components/Cart/CartSummary"
import CartList from "@app/components/Cart/CartList"

const Cart: React.FC<GatsbyTypes.PageCartQuery> = ({ page }) => {
  const { count } = useCheckoutContext()
  const content = useContent(page)

  const checkoutLanguage = {
    checkout: page?.additionalCheckoutButton || "",
    startShopping: page?.additionalStartShopping || "",
  }

  const cartSummaryLanguage = {
    subtotal: page?.additionalSubtotal || "",
    discounts: page?.additionalDiscounts || "",
  }

  return (
    <>
      <Container>
        <Box as="section" py={8} textAlign="center">
          <Heading as="h1" mb={4}>
            {page?.title}
          </Heading>
          {content}
        </Box>
        <Box as="section" py={8} borderTop="1px solid" borderColor="border.default">
          <CartList empty={page?.additionalCartEmpty || ""} remove={page?.additionalRemove || ""} />
          {!!count && <CartSummary language={cartSummaryLanguage} />}
          {!!count && <PaymentTypes justifyContent="center" />}
        </Box>
        {!!count && (
          <Box as="section" py={4} pb={8}>
            <Checkout language={checkoutLanguage} />
          </Box>
        )}
      </Container>
    </>
  )
}

export default Cart
